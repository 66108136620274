import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import MyAppBar from './appbar/MyAppBar';
import { DeviceContainer } from './views/DeviceContainer';
import { useState } from 'react';
import { ResponseLogin } from '../api/identity';
import LoginBox from './LoginBox';

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

/** アプリケーションのメインクラスです */
export default function App() {
  const [loginInfo, setLoginInfo] = useState<ResponseLogin>();
  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <MyAppBar />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          {loginInfo ?
            <DeviceContainer loginInfo={loginInfo} onReload={() => {}} />
            :
            <LoginBox onLoginSuccess={value => setLoginInfo(value)} />
          }
        </Box>
      </Box>
    </ThemeProvider>
  );
}
