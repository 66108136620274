import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import React from 'react';
import { Typography } from '@mui/material';

interface MyAppBarProps {
}
export default function MyAppBar(props: MyAppBarProps) {
  return (
    <MuiAppBar position="absolute">
      <Toolbar
        sx={{
          pr: '24px', // keep right padding when drawer closed
        }} >
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          sx={{ flexGrow: 1 }} >
          Dashboard
        </Typography>
      </Toolbar>
    </MuiAppBar>
  );
}
