import { Card, CardContent, CardHeader, FormControl, Grid, IconButton, ListSubheader, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ResponseGetThumbnailList, getThumbnailList, putThumbnailPin } from "../../api/thumbnail";
import CropDinIcon from '@mui/icons-material/CropDin';
import SplitscreenIcon from '@mui/icons-material/Splitscreen';
import PushPinIcon from '@mui/icons-material/PushPin';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import DeviceNameDialog from "./DeviceNameDialog";

interface DeviceItemProps {
  tenantId: string;
  device_id: string;
  device_name: string;
  device_name_disp: string;
  rotate: string;
  onReload: () => void;
}
export default function DeviceItem(props: DeviceItemProps) {
  const [timeList, setTimeList] = useState<ResponseGetThumbnailList['values']>();
  const [splitItem, setSplitItem] = useState<boolean>(false);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  useEffect(() => {
    getThumbnailList(props.device_id)
    .then(value => {
      setTimeList(value.values);
    })
  }, [props.device_id])
  const handlePinChange = (time: number, pin_action: string) => {
    putThumbnailPin(props.device_id, time, pin_action)
    .then(v => {
      getThumbnailList(props.device_id)
      .then(value => {
        setTimeList(value.values);
      })
    })
  }
  const handleDialogClose = (needReload: boolean) => {
    setShowDialog(false);
    if (needReload) {
      props.onReload();
    }
  }
  return (
    <Grid item xs={12} md={splitItem ? 12 : 6}>
      <Card>
        <CardHeader
          title={<>{props.device_name_disp} <IconButton sx={{opacity: 0.2, ":hover": {opacity: 1}}} onClick={() => setShowDialog(true)}><DriveFileRenameOutlineIcon /></IconButton></>}
          action={
            <IconButton onClick={() => setSplitItem(!splitItem)}>
              {splitItem && <SplitscreenIcon sx={{transform: 'rotate(90deg)'}} />}
              {!splitItem && <CropDinIcon />}
            </IconButton>
          }/>
        <CardContent sx={{display: 'flex', overflowX: 'overlay'}}>
          <TimeListAndImage key={'MAIN'} deviceId={props.device_id} timeList={timeList || []} onPinChange={handlePinChange} rotate={props.rotate} />
          {splitItem && <TimeListAndImage key={'SUB'} deviceId={props.device_id} timeList={timeList || []} onPinChange={handlePinChange} pinnedPrime rotate={props.rotate} />}
        </CardContent>
      </Card>
      {showDialog && <DeviceNameDialog tenantId={props.tenantId} deviceId={props.device_id} deviceName={props.device_name} deviceNameDisp={props.device_name_disp} onClose={handleDialogClose} />}
    </Grid>
  );
}

interface TimeListAndImageProps {
  deviceId: string;
  timeList: ResponseGetThumbnailList['values'];
  onPinChange: (time: number, pin_action: string) => void;
  pinnedPrime?: boolean;
  rotate: string;
}
function TimeListAndImage(props: TimeListAndImageProps) {
  const [selected, setSelected] = useState<ResponseGetThumbnailList['values'][number]>();
  const handleChange = (e: SelectChangeEvent<string>) => {
    const s = e.target.value.split('-');
    setSelected({time: parseInt(s[0]), pinned: s[1] === 'true'})
  }
  useEffect(() => {
    if (props.timeList && !selected) {
      // ピン止めを優先する場合
      if (props.pinnedPrime) {
        setSelected(props.timeList[0]);
      } else {
        // ピン止めを優先しない場合（最初のピン止め以外を採用）
        for (let v of props.timeList) {
          if (!v.pinned) {
            setSelected(v);
            break;
          }
        }
      }
    }
  }, [props.timeList])
  const handlePinChangeClick = () => {
    if (selected) {
      if (confirm(selected.pinned ? 'この画像のピン止めを解除して良いですか？\r\n(撮影日時が31日以前の場合、この操作により再度ピン設定ができなくなります)' : 'この画像をピン止めしますか？'))
      props.onPinChange(selected.time, selected.pinned ? 'REMOVE_PIN' : 'SET_PIN');
    }
  }
  return (
    <div style={{display: 'flex', flexDirection: 'column', paddingLeft: '5px'}}>
      {selected &&
        <>
          <FormControl size="small" sx={{pb: '3px'}}>
            <Select
              value={`${selected.time}-${selected.pinned}`}
              onChange={handleChange}>
              <ListSubheader>ピン止めされた時刻</ListSubheader>
              {props.timeList.filter(v => v.pinned).map(v => {
                return <MenuItem value={`${v.time}-${v.pinned}`}>{toDateString(v.time)} (ピン止め)</MenuItem>
              })}
              <ListSubheader>記録された時刻</ListSubheader>
              {props.timeList.filter(v => !v.pinned).map((v, i) => {
                return <MenuItem value={`${v.time}-${v.pinned}`}>{toDateString(v.time)}{i === 0 && ' (最新)'}</MenuItem>
              })}
            </Select>
          </FormControl>
          <div style={{position: 'relative'}}>
            <img src={`/api/get_thumbnail_image/${props.deviceId}/${selected.time}`} style={{width: '100%', ...getRotateCss(props.rotate)}} />
            <IconButton sx={{position: 'absolute', right: 15, top: 15}} onClick={handlePinChangeClick}>
              {selected.pinned && <PushPinIcon sx={{transform: 'rotate(45deg)'}} color="primary" />}
              {!selected.pinned && <PushPinIcon sx={{transform: 'rotate(45deg)', opacity: '0.5'}} />}
            </IconButton>
            {props.rotate && <span style={{position: 'absolute', right: 15, bottom: 15, border: '1px solid #333', backgroundColor: 'white'}}>180度回転中</span>}
          </div>
        </>
      }
    </div>
  );
}

/** UNIXタイムを日時表記に変換します。 */
function toDateString(unixTime: number) {
  const date = new Date(unixTime * 1000); // Unix timeはミリ秒ではなく秒なので、1000を乗じて変換する
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // 月は0-indexedなので+1する
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');
  return `${year}年${month}月${day}日 ${hours}時${minutes}分${seconds}秒`;
}

/** rotateを示す文字列群からスタイルシート構造体を返します。 */
function getRotateCss(rotate: string) {
  if (rotate) {
    return {transform: rotate};
  } else {
    return {};
  }
}
