import React, { useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import { putDeviceName } from "../../../api/device";

interface DeviceNameDialogProps {
  tenantId: string;
  deviceId: string;
  deviceName: string;
  deviceNameDisp: string;
  onClose: (needReload: boolean) => void;
}
const DeviceNameDialog: React.FC<DeviceNameDialogProps> = (props) => {
  const [deviceNameDisp, setDeviceNameDisp] = useState<string>(props.deviceNameDisp);
  const handleSaveChangeClick = () => {
    // デバイス名を変更
    putDeviceName(props.tenantId, props.deviceId, deviceNameDisp)
    .then(res => {
      if (res.ok) {
        props.onClose(true);
        alert('デバイス名を変更しました。\n(表示順は次回のログイン後に反映されます)')
      } else {
        alert('【エラー】デバイス名を変更できませんでした。')
      }
    })
  }
  return (
    <Dialog
      open={true}
      onClose={() => props.onClose(false)} >
      <DialogTitle>表示用デバイス名を変更</DialogTitle>
      <DialogContent>
        <DialogContentText>
          表示用デバイス名を変更すると、一覧表示での並びを入れ替えることができます。
          一覧表示での並びは表示用デバイス名の昇順です。
        </DialogContentText>
        <TextField
          autoFocus
          required
          margin="dense"
          id="deviceName"
          name="deviceName"
          label="既定のデバイス名(変更できません)"
          fullWidth
          variant="standard"
          value={props.deviceName}
        />
        <TextField
          autoFocus
          required
          margin="dense"
          id="deviceNameDisp"
          name="deviceNameDisp"
          label="表示用デバイス名"
          fullWidth
          variant="standard"
          value={deviceNameDisp}
          onChange={e => setDeviceNameDisp(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => props.onClose(false)}>キャンセル</Button>
        <Button variant="contained" onClick={handleSaveChangeClick} disabled={deviceNameDisp.trim().length === 0} >変更を保存</Button>
      </DialogActions>
    </Dialog>
  );
}
export default DeviceNameDialog;
