import React, { useEffect, useState } from 'react';

/**
 * 簡易的な認証情報の型のサンプル
 */
export type AuthInfo = {
  accessToken: string;
  idToken: string;
  refreshToken: string;
};

// ログイン状態のContext
export const LoggedInContext = React.createContext<boolean>(false);

// 認証情報と認証情報セットのContext
export const AuthInfoContext = React.createContext<
  [AuthInfo, React.Dispatch<React.SetStateAction<AuthInfo>>]
>([{ accessToken: '', idToken: '', refreshToken: '' }, () => { }]);

export const AuthContextProvider: React.FC<{ children: React.ReactNode }> = (props) => {
  // stateの定義
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const [authInfo, setAuthInfo] = useState<AuthInfo>(getDefaultAuthInfo());

  // authInfoのバリデーション
  useEffect(() => {
    // authInfoに正しく値がセットされているかどうかをチェック
    if (authInfo?.accessToken) {
      setAutoInfoToLocalStorage(authInfo);
      setLoggedIn(true);
    } else {
      removeAuthInfoFromLocalStorage();
      setLoggedIn(false);
    }
  }, [authInfo]);
  return (
    <LoggedInContext.Provider value={loggedIn}>
      <AuthInfoContext.Provider value={[authInfo, setAuthInfo]}>
        {props.children}
      </AuthInfoContext.Provider>
    </LoggedInContext.Provider>
  );
};

/**
 * デフォルトのAuthInfoを取得
 * ローカルストレージから取得できた場合はその値をパース
 * 取得できない場合は空の情報を返す
 * @returns
 */
function getDefaultAuthInfo(): AuthInfo {
  const defaultAuthInfo = window.localStorage.getItem('authInfo');
  if (defaultAuthInfo) {
    return JSON.parse(defaultAuthInfo) as AuthInfo;
  } else {
    return { accessToken: '', idToken: '', refreshToken: '' };
  }
}

/**
 * 認証情報をローカルストレージに追加
 * @param authInfo
 */
function setAutoInfoToLocalStorage(authInfo: AuthInfo): void {
  const authInfoStringfy = JSON.stringify(authInfo);
  window.localStorage.setItem('authInfo', authInfoStringfy);
}

function removeAuthInfoFromLocalStorage() {
  window.localStorage.removeItem('authInfo');
}
