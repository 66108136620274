// サムネイル関係のAPI
export interface ResponseGetThumbnailList {
  values: {time: number, pinned: boolean}[];
}
export async function getThumbnailList(deviceId: string) {
  const res = await fetch(
    `/api/get_thumbnail_list/${deviceId}`,
    {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      }
    });
  return res.json() as Promise<ResponseGetThumbnailList>;
}

export async function putThumbnailPin(deviceId: string, timeid: number, pin_action: string) {
  const res = await fetch(
    `/api/put_thumbnail_pin/${deviceId}/${timeid}`,
    {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
      },
      body: `pin_action=${pin_action}`
    });
  return res.json() as Promise<{}>;
}
