import React from 'react';
import { Routes, Route } from "react-router-dom";
import App from './App';
import { AuthContextProvider } from './login/AuthContextProvider';

/** 全体のページのルートとなる要素で、パスによる画面切り替えを行います。 */
export default function AppRoot() {
  return (
    <React.StrictMode>
      <AuthContextProvider>
        <Routes>
          <Route path='/' element={<App />} />
        </Routes>
      </AuthContextProvider>
    </React.StrictMode>
  );
}