import { Button, Container, FormControl, Grid, Paper, TextField } from "@mui/material";
import React, { useState } from "react";
import Title from "./Title";
import { ResponseLogin, doLogin } from "../api/identity";

interface LoginBoxProps {
  onLoginSuccess: (responseLogin: ResponseLogin) => void;
}
export default function LoginBox(props: LoginBoxProps) {
  const [loginKey, setLoginKey] = useState<string>('');
  const [message, setMessage] = useState<string>();
  const handleLogin = () => {
    doLogin(loginKey)
    .then(value => {
      if (value.tenant_id === undefined) {
        setMessage('ログインキーが違います。');
      } else {
        props.onLoginSuccess(value);
      }
    })
  }
  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
            }}>
            <Title>Login</Title>
            <div style={{ width: '100%', flexGrow: 1 }}>
              <FormControl fullWidth sx={{mb: 4}}>
                <TextField
                  required
                  error={message !== undefined}
                  label='ログインキー'
                  value={loginKey}
                  onChange={v => setLoginKey(v.currentTarget.value)}
                  helperText={message !== undefined ? message : undefined} />
              </FormControl>
              <FormControl fullWidth>
                <Button variant="contained" onClick={handleLogin}>ログイン</Button>
              </FormControl>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );

}