import { Container, Grid } from "@mui/material";
import React from "react";
import { ResponseLogin } from "../../api/identity";
import DeviceItem from "./DeviceItem";

export interface DeviceContainerProps {
  loginInfo: ResponseLogin;
  onReload: () => void;
}
export const DeviceContainer: React.FC<DeviceContainerProps> = (props) => {
  return (
    <Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        {props.loginInfo.devices.map(device => {
          return (
            <DeviceItem
              tenantId={props.loginInfo.tenant_id}
              device_id={device.device_id}
              device_name={device.device_name}
              device_name_disp={device.device_name_disp}
              onReload={props.onReload}
              rotate={device.rotate} />
          )
        })}
      </Grid>
    </Container>
  );
}
