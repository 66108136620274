// デバイス関係のAPI

export async function putDeviceName(tenantId: string, deviceId: string, device_name: string) {
  const params = new URLSearchParams();
  params.append('tenant_id', tenantId);
  params.append('device_name', device_name);
  return fetch(
    `/api/put_device_name/${deviceId}`,
    {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
      },
      body: params
    });
}
