// ログイン関係のAPI
interface IDevices {
  device_id: string;
  device_name: string;
  device_name_disp: string;
  rotate: string;
}
export interface ResponseLogin {
  tenant_id: string;
  devices: IDevices[];
}
export async function doLogin(loginKey: string) {
  // if (!loginKey) return {};
  const res = await fetch(
    '/api/post_login',
    {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
      },
      body: `login_key=${loginKey}`
    });
  return res.json() as Promise<ResponseLogin>;
}